<template>
  <div class="coupon-page px-7 py-9">
    <v-row :key="keyHandler">
      <v-col class="add-coupon" cols="12" md="8">
        <v-form
          v-model="validForm"
          @submit.prevent="submitHandler"
          ref="couponRef"
        >
          <div class="white-bg b-cover radius-5">
            <div class="py-6 px-5 b-bottom">
              <span class="font-18 font-500 black--text"
                >Create a coupon code</span
              >
            </div>

            <div class="px-5 py-6 d-flex align-center b-bottom">
              <div>
                <span class="d-block font-500 font-13 black-grey--text mb-1"
                  >Coupon Code</span
                >
                <base-input
                  :rules="[(v) => !!v || '']"
                  v-model="obj.code"
                  placeholder="Type coupon code"
                  class="d-block"
                ></base-input>
              </div>
              <p class="ma-3 mt-7">-OR-</p>
              <v-btn
                class="mx-0 d-block mt-6"
                style="margin: auto"
                dark
                color="darkblue"
                @click="generateCode"
              >
                <span class="mx-6 d-block">GENERATE</span></v-btn
              >
            </div>

            <!-- details -->
            <div class="b-bottom">
              <!-- header -->
              <div class="px-5 d-flex align-center py-6 b-bottom">
                <div class="mr-4">
                  <span class="d-block font-500 font-13 black-grey--text mb-1"
                    >Discount Type</span
                  >
                  <base-select
                    :rules="[(v) => !!v || '']"
                    v-model="value"
                    :items="values"
                    placeholder="Discount Type"
                    :returnObject="false"
                  ></base-select>
                </div>

                <div v-if="value == 'amount'">
                  <span class="d-block font-500 font-13 black-grey--text mb-1"
                    >Amount</span
                  >
                  <base-input
                    :rules="[(v) => !!v || '']"
                    type="number"
                    v-model="obj.amount"
                    placeholder="Number"
                  ></base-input>
                </div>

                <div v-if="value == '%'">
                  <span class="d-block font-500 font-13 black-grey--text mb-1"
                    >Discount</span
                  >
                  <base-input
                    :rules="[(v) => !!v || '']"
                    type="number"
                    v-model="obj.value"
                    placeholder="Number"
                  ></base-input>
                </div>
              </div>

              <!-- list -->
              <div class="coupon-list px-6 py-5">
                <div class="d-flex justify-space-between">
                  <v-checkbox
                    v-model="selectAll"
                    label="APPLY TO ALL EXPERIENCES"
                    dense
                    @change="selectAllExp"
                    :hide-details="true"
                    color="secondary"
                    class="check-secondary font-500"
                  ></v-checkbox>
                  <base-input
                    :rules="[]"
                    placeholder="Search Activities"
                    @input="searchActivities"
                  ></base-input>
                </div>

                <div class="divider-dark my-2 mb-5"></div>
                <v-row class="activities-list" v-if="isLoadingActivities">
                  <v-col
                    class="py-0 pb-1"
                    cols="6"
                    v-for="(item, i) in [
                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14
                    ]"
                    :key="i"
                  >
                    <v-skeleton-loader type="chip"></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row class="activities-list" v-else>
                  <v-col
                    class="py-0 pb-1"
                    cols="6"
                    v-for="activity in activities"
                    :key="activity.id"
                  >
                    <v-checkbox
                      v-model="obj.activities"
                      :label="activity.title"
                      :value="activity.id"
                      :hide-details="true"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </div>
              <!-- date -->
              <v-row class="px-6 py-4 pb-7 mt-2">
                <v-col cols="12" md="5">
                  <span class="d-block font-500 font-13 black-grey--text mb-1"
                    >Expiry Date</span
                  >
                  <base-date
                    @valueHandler="validityHandler"
                    :value="validity"
                    :key="validity"
                    clearable
                  ></base-date>
                </v-col>
                <v-col cols="12" md="1">
                  <p class="mt-7">-OR-</p>
                </v-col>
                <v-col cols="12" md="5">
                  <span class="d-block font-500 font-13 black-grey--text mb-1"
                    >Number Of Use
                  </span>
                  <base-input
                    placeholder="Number Of Use"
                    v-model="obj.totalUse"
                    type="number"
                    :rules="[]"
                  ></base-input>
                </v-col>
                <v-col cols="12" md="9">
                  <span class="font-12 font-500 black-grey--text d-block"
                    >Description
                  </span>
                  <base-textarea
                    :rules="[]"
                    v-model="obj.description"
                    placeholder="Write down more details"
                  >
                  </base-textarea>
                </v-col>
              </v-row>
            </div>

            <!-- submit btn -->
            <div class="text-right px-6 py-5">
              <v-btn
                color="green"
                type="submit"
                :disabled="!validForm"
                :dark="validForm"
              >
                <span class="px-6"> Save</span></v-btn
              >
            </div>
          </div>
        </v-form>
      </v-col>

      <!-- list coupons -->
      <v-col cols="12" md="4">
        <!-- active coupons -->
        <div class="white-bg b-cover active-list">
          <div class="pb-2 whitebg">
            <span
              class="d-block pt-4 px-5 font-16 font-500 black--text pb-3 white"
              >Active Coupons</span
            >
            <div class="list" v-if="loadingCoupons">
              <div
                class="d-flex py-3 px-5 align-center justify-space-between b-bottom-dark"
                v-for="(item, i) in [1, 2, 3, 4, 5, 6]"
                :key="i"
              >
                <v-col cols="12" md="5" class="ma-0 pa-0">
                  <v-skeleton-loader type="list-item"></v-skeleton-loader>
                </v-col>
              </div>
            </div>
            <div class="list" v-else>
              <div
                class="d-flex py-3 px-5 align-start justify-space-between b-bottom-dark"
                v-for="(coupon, i) in actives"
                :key="i"
              >
                <!-- coupon title -->
                <v-col cols="12" md="5" class="ma-0 pa-0">
                  <div class="d-block font-500 font-18 black--text">
                    {{ coupon.code }}
                  </div>
                  <span
                    cols="12"
                    md="2"
                    class="d-block font-13 black-grey--text"
                    >{{ coupon.description || "" }}</span
                  >
                  <v-checkbox
                    v-model="coupon.public"
                    @change="couponPublicHandler($event, coupon)"
                    label="Public"
                  ></v-checkbox>
                </v-col>

                <!-- coupon times -->
                <v-col cols="12" md="5" class="ma-0 pa-0">
                  <span class="d-block font-14 black-grey--text"
                    >Times used</span
                  >
                  <span
                    v-if="coupon.totalUse"
                    class="d-block font-500 font-16 black--text"
                    >{{ coupon.use }} / {{ coupon.totalUse }}</span
                  >
                  <span v-else class="d-block font-500 font-16 black--text"
                    >No Limit</span
                  >
                  <span class="d-block font-14 black-grey--text"
                    >Expiry Date</span
                  >
                  <span
                    v-if="coupon.validity"
                    class="d-block font-13 black--text"
                    >{{ $moment(coupon.validity).format("LL") }}</span
                  >
                </v-col>

                <!-- actions -->
                <v-col cols="12" md="2" class="d-flex align-center">
                  <v-icon color="grey" @click="editHandler(coupon)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon color="grey" @click="deleteConfirm(coupon)"
                    >mdi-close</v-icon
                  >
                </v-col>
              </div>
            </div>
          </div>
        </div>

        <!-- inactive coupons -->
        <div class="white-bg b-cover mt-5 active-list">
          <div class="pb-2 b-bottom whitebg">
            <span
              class="d-block pt-4 px-5 font-16 font-500 black--text pb-3 white"
              >Inactive Coupons</span
            >
            <div class="list" v-if="loadingCoupons">
              <div
                class="d-flex py-3 px-5 align-center justify-space-between b-bottom-dark"
                v-for="(item, i) in [1, 2, 3, 4, 5, 6]"
                :key="i"
              >
                <v-col cols="12" md="5" class="ma-0 pa-0">
                  <v-skeleton-loader type="list-item"></v-skeleton-loader>
                </v-col>
              </div>
            </div>
            <div class="list" v-else>
              <div
                class="d-flex py-3 px-5 align-center justify-space-between b-bottom-dark"
                v-for="(coupon, i) in inActives"
                :key="i"
              >
                <!-- coupon title -->
                <v-col cols="12" md="5" class="ma-0 pa-0">
                  <div class="d-block font-500 font-18 black--text">
                    {{ coupon.code }}
                  </div>
                  <span
                    cols="12"
                    md="2"
                    class="d-block font-13 black-grey--text"
                    >{{ coupon.description || "" }}</span
                  >
                </v-col>

                <!-- coupon times -->
                <v-col cols="12" md="5" class="ma-0 pa-0">
                  <span class="d-block font-14 black-grey--text"
                    >Times used</span
                  >
                  <span
                    v-if="coupon.totalUse"
                    class="d-block font-500 font-16 black--text"
                    >{{ coupon.use }} / {{ coupon.totalUse }}</span
                  >
                  <span v-else class="d-block font-500 font-16 black--text"
                    >No Limit</span
                  >
                  <span class="d-block font-14 black-grey--text"
                    >Expiry Date</span
                  >
                  <span
                    v-if="coupon.validity"
                    class="d-block font-13 black--text"
                    >{{ $moment(coupon.validity).format("LL") }}</span
                  >
                </v-col>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import voucherCode from "voucher-code-generator";

export default {
  data: () => ({
    validForm: false,
    coupons: [{ title: "bla" }, { title: "bla" }, { title: "bla" }],
    couponCode: "",
    percentage: "",
    number: null,
    activities: [],
    actives: [],
    inActives: [],
    obj: { validity: "", activities: [] },
    values: [
      { title: "%", value: "%" },
      { title: "amount", value: "amount" }
    ],
    value: {},
    keyHandler: false,
    couponID: "",
    deleteDialog: false,
    editing: false,
    maxNumber: "",
    selectAll: false,

    // permissions
    couponDelete: { Coupons: "delete" },
    couponEdit: { Coupons: "edit" },
    couponAdd: { Coupons: "add" },
    total: 0,
    validity: undefined,
    loadingCoupons: true,
    isLoadingActivities: false
  }),
  methods: {
    generateCode() {
      this.obj.code = voucherCode.generate({
        length: 8,
        count: 5
      })[0];
      this.keyHandler = !this.keyHandler;
    },
    validityHandler(val) {
      this.validity = val;
      this.obj.validity = val;
    },
    async editHandler(coupon) {
      await this.searchActivities();
      let { data } = await this.$axios.get(`discount/${coupon._id}`);
      this.editing = true;
      this.keyHandler = !this.keyHandler;
      if (data) {
        this.obj.code = data.data.code;
        this.obj.description = data.data.description || "";
        if (data.data.amount) {
          this.value = "amount";
          this.obj.amount = data.data.amount;
        } else {
          this.value = this.values[0].value;
          this.obj.value = data.data.value;
        }
        this.obj.totalUse = data.data.totalUse;
        this.obj.activities = data.data.activities;
        if (data.data.validity) {
          this.validity = this.$moment(data.data.validity).format("YYYY-MM-DD");
        }
        this.couponID = data.data._id;
      }
    },
    async couponPublicHandler(event, coupon) {
      let res = await this.$axios.put(`discount/edit/new/${coupon._id}`, {
        public: event
      });

      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: `Coupon's public has been edit successfully`,
          color: "success"
        });
        await this.fetchCoupons();
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    deleteConfirm(coupon) {
      this.deleteDialog = true;
      this.couponID = coupon._id;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(
        `/discount/delete/new/${this.couponID}`
      );

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        await this.fetchCoupons();
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    async selectAllExp() {
      this.obj.activities = this.selectAll
        ? this.activities.map((activity) => {
            return activity.id;
          })
        : [];
    },
    async submitHandler() {
      if (this.value == "amount") {
        this.obj.value = "";
      }
      if (this.value == "%") {
        this.obj.amount = "";
      }

      if (this.validity && this.validity != "") {
        this.obj.validity = this.$moment
          .utc(this.validity)
          .startOf("day")
          .toISOString();
      }
      this.obj.description = this.obj.description || "";
      // valid activity
      if (this.obj.activities.length == 0) {
        this.$store.dispatch("showSnack", {
          text: "At least one activity should be selected",
          color: "error"
        });
        return;
      }
      // send request
      let res;
      if (this.editing) {
        res = await this.$axios.put(
          `discount/edit/new/${this.couponID}`,
          this.obj
        );
      } else {
        res = await this.$axios.post("discount/create/new", this.obj);
      }

      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: `Coupon has been ${
            this.editing ? "edited " : "added "
          } successfully`,
          color: "success"
        });
        await this.searchActivities();
        await this.fetchCoupons();
        this.obj = {};
        this.$refs.couponRef.reset();
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    async searchActivities(searchWord) {
      this.isLoadingActivities = true;
      let { data } = await this.$axios.get(
        `activity/all/new?searchWord=${searchWord || ""}&pageSize=`
      );
      if (data) {
        this.total = data.data.count;
        this.activities = data.data.map((activity) => {
          return {
            id: activity._id,
            title: activity.title
          };
        });
      }
      this.isLoadingActivities = false;
    },
    async fetchCoupons() {
      this.loadingCoupons = true;
      let { data } = await this.$axios.get("/discount/all/new?pageSize=");
      if (data) {
        this.coupons = data.data;
        this.inActives = this.coupons.filter((coupon) => {
          let isExpired =
            coupon.validity != undefined &&
            !this.$moment().isBefore(this.$moment(coupon.validity));
          return isExpired || (!isExpired && coupon.totalUse == coupon.use);
        });
        this.actives = this.coupons.filter((item) => {
          return !this.inActives.some((coupon) => {
            return coupon._id === item._id;
          });
        });
      }
      this.loadingCoupons = false;
    }
  },
  async created() {
    await this.searchActivities();
    await this.fetchCoupons();
  }
};
</script>

<style lang="scss">
.coupon-page {
  background: #fbfbfd;
  .check-secondary {
    label {
      color: $secondary;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .coupon-list {
    overflow: hidden;
    background: #f1f1f1;
    .activities-list {
      max-height: 250px;
      overflow-y: auto;
    }
  }
  .active-list {
    max-height: 40vh;
    overflow: hidden;
    .list {
      overflow-y: auto;
      max-height: calc(40vh - 50px);
    }
  }
  .add-coupon {
    height: calc(100vh - 60px);
    overflow: auto;
  }
}
</style>
